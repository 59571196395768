import { t } from 'i18n';
import { getPublicSiteUrl } from './index';
import { ONE_MINUTE } from './durations';

export const breakPoints = {
  extraLargeBreakPoint: 1300,
  largeBreakPoint: 1215,
  midBreakPoint: 920,
  smallBreakPoint: 768,
  extraSmallBreakPoint: 425,
  tinyBreakPoint: 425,
};
export const SUCCESSFUL_HTTP_STATUS = 200;
export const UNAUTHORIZED_HTTP_STATUS = 401;
export const SERVER_ERROR_HTTP_STATUS = 500;
export const MAINTENANCE_HTTP_STATUS = 503;

// graphql error codes
export const INVALID_OTP_ERROR = 'INVALID_OTP_ERROR';
export const INVALID_RECORD_ERROR = 'ACTIVE_RECORD/RECORD_INVALID';

export const USER = {
  MAX_AGE: 99,
  MIN_AGE: 18,
};

export const CREDIT_CARD_PLAZA_CODE = '975';

const INVESTOR_STATUS = [
  'profile_started',
  'profile_completed',
  'pld_validating',
  'id_validating',
  'address_validating',
  'pld_reviewing',
  'contract_unsigned',
  'authorized',
  'blocked',
  'partially_blocked',
  'inactive',
  'canceled',
  'risk_signed',
  'personal_archive_incomplete',
];

export const REQUISITION_ATTRIBUTES = {
  QUALIFICATION: 'qualification',
  BC_SCORE: 'bc_score',
  DESTINATION: 'destination',
  DAYS_TO_END: 'days_to_end',
  MISSING_AMOUNT: 'missing_amount',
};

export const INVESTOR_STATUS_KEYS = INVESTOR_STATUS.reduce(
  (acc, key) => ({ ...acc, [key.toUpperCase()]: key }),
  {},
);

export const userStatus = INVESTOR_STATUS_KEYS;
export const USER_STATUSES = INVESTOR_STATUS_KEYS;

export const IDENTITY_VERIFICATION_STATUSES = {
  STARTED: 'started',
  WAITING_FOR_RESPONSE: 'waiting_for_response',
  SUCCESS: 'success',
  UPDATE_INFORMATION: 'update_information',
  MANUAL_VERIFICATION: 'manual_verification',
  USER_VERIFICATION: 'user_validation',
  ERROR: 'error',
  BLOCKED: 'blocked',
};

export const CLABE_MAX_RESTRICTION = '18';
export const REQUIRED_PASSWORD_LENGTH = 8;
export const DEFAULT_PHONE_LENGTH = 10;
export const DEFAULT_PHONE_CODE = '52';

const publicSiteUrl = getPublicSiteUrl();

export const publicUrls = {
  statistics: `${publicSiteUrl}/estadisticas`,
  helpCenter: 'https://centrodeayuda.yotepresto.com',
  guide: 'https://centrodeayuda.yotepresto.com/hc/es-419/articles/360015334651',
  termConditions: `${publicSiteUrl}/terminos-condiciones`,
  privacy: `${publicSiteUrl}/aviso-privacidad`,
  faq: 'https://centrodeayuda.yotepresto.com/hc/es-419/sections/360002278152-Preguntas-frecuentes',
  home: `${publicSiteUrl}/home`,
  blockedInvestorAccount: 'http://www.yotepresto.com/cuentas-bloqueadas',
  // TODO: Set correct urls to apps
  iosApp: 'https://www.apple.com/ios/app-store/',
  androidApp: 'https://play.google.com/store',
};

export const messages = {
  inputMessages: {
    passwordMaxChar: `${REQUIRED_PASSWORD_LENGTH} caracteres`,
  },
  required: 'Este valor es requerido',
  invalid: 'Este valor es inválido',
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isChromeMobile = !!window.chrome;

export const ZIPCODE_LENGTH = 5;

export const productTypes = {
  grace_period: {
    type: 'G',
    title: 'Período de gracia',
    description:
      'Debido a la emergencia sanitaria por la pandemia de COVID-19, el titular de este crédito recibió un período de gracia, es decir, una prórroga de uno a cuatro meses. Las mensualidades no pagadas serán recalendarizadas para que sean cubiertas al final del plazo original del crédito. Por su parte, los intereses ordinarios no pagados y generados durante el período de gracia se distribuirán en las cuotas restantes.',
  },
  re_calendarization: {
    type: 'C',
    title: 'Recalendarización',
    description:
      'Al titular de este crédito le fue autorizada una recalendarización, la cual consiste en trasladar sus mensualidades vencidas al final del plazo original del crédito.',
  },
  zapopan: {
    type: t('detailRequisition.zapopanWarranty.type'),
    title: t('detailRequisition.zapopanWarranty.title'),
    description: t('detailRequisition.zapopanWarranty.description'),
    moreInfo: t('detailRequisition.zapopanWarranty.moreInfo'),
  },
  restructuring: {
    type: 'R',
    title: 'Reestructurado',
    description:
      'El titular de este crédito recibió una reestructura, es decir, ampliamos el plazo de su crédito, para poder bajar su mensualidad. Por lo tanto, el monto que recibías mensualmente disminuirá, pero no así la tasa de interés. Además, la fecha de pago también puede haberse modificado, lo cual podrás verificar en tu Calendario.',
  },
  lquita: {
    type: t('detailRequisition.lquita.type'),
    title: t('detailRequisition.lquita.title'),
    description: t('detailRequisition.lquita.description'),
  },
};

export const registerStats = {
  REGISTERED_USERS: '1 millón',
  ANNUAL_RETURNS: '17%',
  PUNCTUAL_RATE: '96%',
};

export const STATUS_REQUISITION_NAME_MAP = {
  'En Proceso de Pago': 'En tránsito',
  waiting_for_selfie: 'Por Depositar',
  waiting_for_addendum: 'Por Depositar',
  waiting_for_credit_review: 'Por Depositar',
  waiting_for_treasury_review: 'Por Depositar',
  waiting_to_deposit_credit: 'Por Depositar',
  rejected_by_addendum: 'Por Depositar',
  rejected_by_selfie: 'Por Depositar',
  canceled_bad_references: 'Por Depositar',
  canceled_by_other_reason: 'Por Depositar',
  canceled_double_req: 'Por Depositar',
  canceled_formalize_expired: 'Por Formalizar',
  rejected_by_bcscore: 'Por Formalizar',
  formalize: 'Por Formalizar',
  pre_formalized: 'Por Formalizar',
};

// ttl: time to logout
export const getSessionConfig = () => ({
  ttl: 5 * ONE_MINUTE,
  alertBefore: ONE_MINUTE,
});

export const factorTypes = {
  SMS: 'sms',
  AUTHENTICATOR_APP: 'authenticator_app',
  YOTEPRESTO_APP: 'yotepresto_app',
};

export const features = {};

export const LENDING_AMOUNT_MULTIPLIER = 100;

export const MIN_LENDING_AMOUNT = 200;

export const MAX_LENDING_AMOUNT = 400000;

export const LIMIT_LEVELS = {
  LEVEL_ONE: 'level_one',
  LEVEL_TWO: 'level_two',
  LEVEL_THREE: 'level_three',
};

const firstLevelWarns = [
  'overfunded',
  'account_value_on_fifty_percent',
  'transactions_on_ninety_percent',
  'transactions_on_sixty_percent',
  'transactions_on_thirty_percent',
  'exceeded_transactional_limit',
  'multiple_users',
];

const [
  OVERFUNDED,
  ACCOUNT_VALUE_ON_FIFTY_PERCENT,
  TRANSACTIONS_ON_NINETY_PERCENT,
  TRANSACTIONS_ON_SIXTY_PERCENT,
  TRANSACTIONS_ON_THIRTY_PERCENT,
  EXCEEDED_TRANSACTIONAL_LIMIT,
  MULTIPLE_USERS,
] = firstLevelWarns;

export const firstLevelWarnsKeys = {
  OVERFUNDED,
  ACCOUNT_VALUE_ON_FIFTY_PERCENT,
  TRANSACTIONS_ON_NINETY_PERCENT,
  TRANSACTIONS_ON_SIXTY_PERCENT,
  TRANSACTIONS_ON_THIRTY_PERCENT,
  EXCEEDED_TRANSACTIONAL_LIMIT,
  MULTIPLE_USERS,
};

export const genres = [
  { value: 'male', label: 'Hombre', abbr: 'H' },
  { value: 'female', label: 'Mujer', abbr: 'M' },
];

/**
 * To disabled navigation when user is blocked by limits
 * add OVERFUNDED in BLOCKED_BY_LIMITS_STATUSES
 */
export const BLOCKED_BY_LIMITS_STATUSES = [
  firstLevelWarnsKeys.OVERFUNDED,
];

export const OTP_CODE_LENGHT_DEFAULT = 6;

export const OTP_TYPES = {
  EMAIL: 'email',
  SMS: 'sms',
  YOTEPRESTO_APP: 'yotepresto_app',
  APP_AUTHENTICATOR: 'authenticator_app',
};

export const MOBILE_DEVICES_TYPES = {
  SMARTPHONE: 'smartphone',
  TABLET: 'tablet',
};

// Time to expiry token generated
export const EXPIRE_TOKEN_AFTER_TIME = 90000;

export const CURRENT_ROLE = 'investor';

export const DECIMALS_FIXED_TO = 2;

export const RESEND_SMS_REMAINING_TIME = 30000;

/**
 * @constant REFETCH_ACCOUNT_STATEMENTS_WAIT_FOR
 *
 * account statement resource sometimes cannot be loaded after user logged in
 * Application uses this waiting time in milliseconds to try
 * fetch data over new if is flagged as caching
 *
 * default 30 seconds
 */

export const REFETCH_ACCOUNT_STATEMENTS_WAIT_FOR = 30000;

export const REFETCH_PORTFOLIO_INVESTMENTS_WAIT_FOR = 30000;

export const REQUISITION_STATUS = {
  CANCEL: 'Cancelada',
  FORMALIZE: 'Por Formalizar',
};

export const TOAST_AUTO_CLOSE_TIME = 4000;

export const CURRENT_YEAR = new Date().getFullYear();

export const PASSWORD_ERROR_TYPES = {
  // Should be larger than MIN_LENGTH
  MIN_LENGTH: 'min_length',
  // Should contain at least one digit
  MISSING_DIGIT: 'min_numerically',
  // Should contain at least one symbol E.G.($@.-)
  SPECIAL_CHAR: 'min_special_chars',
  // Should not contain the word yotepresto or legal name
  CONTAINS_RESERVED_WORDS: 'reserved_words',
  // Should contain at least N(defined by API) alphanumeric chars
  CONTAINS_ALPHABETIC: 'min_alphabetic',
  // Should not contain the email of the user
  CONTAINS_EMAIL: 'email',
  // Should not contain user ID
  CONTAINS_ID: 'ids',
  // Should not contain 3 chars repeated E.G.(paaasword)
  REPEATED_CHARS: 'repeated',
  // Should not contain 3 consecutive chars E.G.(1234 or abcd)
  CONSECUTIVE_CHARS: 'max_consecutive_chars',
  // Should contain up to N(defined by API) sequential chars
  SEQUENTIAL_CHARS: 'max_sequential_chars',
  // Should not use the same password as before
  CURRENT_PASSWORD: 'current_password',
};

export const WS_EVENT_NAMES = {
  MATI_STATUS: 'mati_status',
  ORDER_FAILED: 'ticket_error',
  PORTFOLIO_UPDATING: 'portfolio',
  ORDER_CREATED: 'ticket_success',
  TRANSACTIONS_UPDATING: 'movements',
  SESSION_STATUS: 'session_information',
  PORTFOLIO_BRIEF: 'portfolio-dashboard',
  ACCOUNT_BALANCE_BRIEF: 'account-statement',
  SESSION_UPDATING: 'session_information_updated',
  BANK_ACCOUNT_VALIDATED: 'withdrawal_bank_account',
  DIVERSIFICATION_UPDATING: 'update-diversification',
  ACCOUNT_STATEMENT_PDF_CREATED: 'account_statement_pdf',
};

export const WITHDRAWAL_BANK_ACCOUNT_STATUS = {
  VALIDATING: 'validating',
  VALID: 'validated',
  INVALID: 'invalidated',
};

export const STORAGE_KEYS = {
  OTP_EXPIRY_LEFT_TIME: 'APP_OTP_EXPIRY_LEFT_TIME',
};
export const DEFAULT_CURRENCY = 'MXN';

export const PROOF_OF_ADDRESS_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  ON_REVIEW: 'reviewing',
  APPROVED: 'approved',
};
